const hasPathnameExtraSlashes = (pathname:string) => {
  const regexExtraSlashes = /\/\/+/g;
  const regexpTrailingSlashes = /\/+$/;

  const matchExtraSlashes = pathname.match(regexExtraSlashes);
  const matchTrailing = pathname.match(regexpTrailingSlashes);

  return (matchExtraSlashes) || (matchTrailing && matchTrailing['input'] !== '/');
};

export default hasPathnameExtraSlashes;

import React, { lazy } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

import { MultiLangProvider } from './context/MultiLang'
import getClearedPathname from './utils/getClearedPathname';
import hasPathnameExtraSlashes from './utils/hasPathnameExtraSlashes';


const Main = lazy(() => import('./pages/Main'));


function App() {
	const location = useLocation();

	return (
		<MultiLangProvider>
			<ParallaxProvider>

				{
					hasPathnameExtraSlashes(location.pathname)
						? <Navigate to={getClearedPathname(location.pathname)} />
						: <Routes>
							<Route path="/" element={<Main />} />
							<Route path='*' element={<Navigate to='/' />} />
						</Routes>
				}

			</ParallaxProvider>
		</MultiLangProvider>
	);
}

export default App;

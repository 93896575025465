import React, { createContext, useState, useCallback } from 'react';

import { general as ru } from './words/ru/index'
import { general as en } from './words/en/';

import {
  IAllLanguagesWords,
  IMultiLangState,
} from './MultiLang.types';


export type IMultiLangWords = ReturnType<() => typeof en>;


const words: IAllLanguagesWords = {
  ru,
  en,
}

const defaultLang = localStorage.getItem('lang') || 'en';

const MultiLangContext = createContext<IMultiLangState>({
  words: words[defaultLang],
  changeLang: (lang: string, isUpdateLocale?: boolean) => {
  },
  selectedLang: '',
});

MultiLangContext.displayName = 'MultiLangContext';

const MultiLangProvider = (props: any) => {
  const [state, setState] = useState({
    selectedLang: defaultLang,
    words: words[defaultLang],
  });

  const changeLang = useCallback((lang: string, isUpdateLocale?: boolean) => {
    const newWords = words[lang];

    setState(prevState => {
      if (prevState.selectedLang === lang || !newWords) {
        return prevState;
      }
      localStorage.setItem('lang', lang);

      return {
        selectedLang: lang,
        words: newWords,
      }
    });
  }, []);

  const value: IMultiLangState = {
    words: state.words,
    selectedLang: state.selectedLang,
    changeLang,
  }

  return <MultiLangContext.Provider
    {...props}
    value={value}
  />
}

export { MultiLangContext, MultiLangProvider };
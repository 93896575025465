export const general = {
  appTitle: 'S-Healthcare - организация в индустрии медицинского каннабиса.',
  name: 'S-Healthcare',
  main: 'Главная',
  aboutUs: 'О нас',
  market: 'Рынок',
  missions: 'Цели',
  conditions: 'Условия',
  cabinet: 'Личный кабинет',
  mission: 'Цель',
  month: 'месяц',
  heroDescription: 'Структура коммерческих организаций в сфере <strong>медицинского каннабиса.</strong>',

  // about us
  aboutName: 'S-HEALTHCARE <strong>SSG HOLDINGS AG</strong>',
  aboutSubhead: 'Каннабис-инновация полного цикла',
  ourMission: 'Наша миссия:',
  aboutDesc1: 'Внести собственный вклад в развитие и массовое международное принятие отрасли медицинского каннабиса, как альтернативного, а порой даже единственного метода лечения против множества заболеваний. Сделать доступным в территориальном и финансовом планах употребление медицинского каннабиса для всех, кто в этом нуждается.',
  aboutDesc2: 'это швейцарская компания, которая занимается реализацией производства полного цикла от выращивания и переработки до поставки в торговые точки планеты готовой продукции премиум класса в доступных формах.',
  // tab1
  production: 'Производство',
  tabTitle1: 'Компания Производство',
  tabDesc1: '<strong>Цель компании</strong> — стать лидером Европейского рынка выращивания, переработки и ритейл продаж высокосодержащего CBD и THC каннабиса посредством вертикальной интеграции группы компаний холдинга.',
  tabSlides1: {
    desc1: 'Поиск локаций под аренду, оценка инфраструктуры, утверждение условий, подписание договора',
    desc2: 'Заказ и оплата необходимого оборудования. Начало поиска и формирование команд для подготовки инфраструктуры под налаживание производства (тоннели, теплицы, индор)',
    desc3: 'Начало монтажа оборудования в тоннелях и гринхаус. Начало ремонтных работ в  индоре',
    desc4: 'Окончание монтажа оборудования в тоннелях и гринхаус. Начало монтажа оборудования в индоре',
    desc5: 'Закупка клонов, земли, горшков, удобрений. Продолжение процесса монтажа оборудования в индоре',
    desc6: 'Старт посадки в тоннелях и гринхаус. Финальный этап подготовки индора к выращиванию',
    desc7: 'Урожай в тоннелях и теплицах начинает расти. Первый урожай в тоннелях и гринхаус.Инспекция индора для запуска производства. Запуск выращивания в индоре.'
  },
  // tab2
  extraction: 'Экстракция',
  tabTitle2: 'Компания Экстракция',
  tabDesc2: '<strong>Цель компании</strong> — производство продукции на основе экстракта масла каннабиса с высоким содержанием CBD',
  tabSlides2: {
    desc1: 'Поиск помещения. Подбор оборудования, получение документации. Оплата оборудования',
    desc2: 'Создание проектов относительно оборудованию \nа)\xA0GMP проект; б)\xA0проект освещения; в)\xA0проект вентиляции; г)\xA0проект лаборатории;',
    desc3: 'Старт ремонта помещения',
    desc4: 'Старт доставки оборудования \nПодбор управляющей команды и базового линейного персонала',
    desc5: 'Старт монтажа оборудования',
    desc6: 'Дополнительный месяц на возможные замечания от ХАССП и GMP и их устранение',
    desc7: 'Пусконаладка и тестовый старт',
    desc8: 'Выход на производственную мощность',
  },
  // tab3
  sale: 'Реализация',
  tabTitle3: 'Компания Реализация',
  tabDesc3: '<strong>Задача компании</strong> — запуск маркетплейса, который заберет на себя большинство интернет-трафика по ключевым запросам поиска и покупки товаров с содержанием CBD, а также установка вендинговых автоматов на территории Европы',
  tabSlides3: {
    desc1: 'Выбор производителя вендинговых автоматов, получение цен, заказ первого опытного образца на нашем дизайне, оплата образца, доставка образца в офис',
    desc2: 'Создание проектов относительно оборудованию \nа)\xA0GMP проект; б)\xA0проект освещения; в)\xA0проект вентиляции; г)\xA0проект лаборатории;',
    desc3: 'Старт ремонта помещения',
    desc4: 'Доставка партии автоматов маленького размера (авиа) \nНачало размещения автоматов по точкам',
    desc5: 'Доставка партии автоматов большого размера. \nНачало размещения автоматов по точкам.',
    desc6: 'Установка от 3 000 вендинговых аппаратов по Европе',
    desc7: 'Заказ второй партии вендинговых автоматов \nУстановка Х вендинговых автоматов',
  },
  // tab4
  products: 'Продукты',
  tabTitle4: 'Компания Продукты',
  tabDesc4: '<strong>Задача компании</strong> — выкуп сырья у компаний Производство и Экстракция, запуск производственных помещений для дальнейшей сушки, сортировки и упаковки в конечный бренд для поставок на ритейл сети по Швейцарии и Европе',
  tabSlides4: {
    desc1: 'Анализ рынка, конкурентных компаний, целевой аудитории (Контент-анализ , SWOT-анализ… ) \nБренд — Брендбук, нейминг, дизайн \nСтратегия позиционирования',
    desc2: 'Поиск главного офиса, подписание договора \nСбор команды продаж',
    desc3: 'Заказ первой партии упаковок, оплата, начало производства',
  },

  // market
  marketTitle: 'Рынок в цифрах',
  billion: 'млрд',
  marketContent: {
    text1: '<b>Более 50 стран</b> по всему миру легализовали некоторые формы медицинского каннабиса, а шесть стран легализовали каннабис для рекреационного использования взрослыми.',
    text2: 'Несмотря на широкий глобальный запрет, более <b>263 миллионов</b> человек во всем мире потребляют каннабис каждый год.',
    text3: 'Общий мировой рынок каннабиса (регулируемый и незаконный) оценивается в <b>340 миллиардов</b> долларов США.',
  },
  marketCountry: {
    asia: 'Азия',
    northAmerica: 'Северная\nАмерика',
    europe: 'Европа',
    africa: 'Африка',
  },

  // objective
  objectiveGoal: 'Цель S&#8209;HealthCare',
  objectiveGoals: 'Цели холдинга S&#8209;HealthCare',
  objectiveTitle: 'Капитализация быстрорастущих компаний',
  objectiveSubhead1: 'Не просто повторить успех коллег из США, а превзойти их показатели, используя преимущества в технологии, современном оборудовании и эксклюзивных методиках производства и дистрибуции.',
  objectiveSubhead2: 'Ожидаемая доходность и стоимость акций может быть ЗНАЧИТЕЛЬНО увеличена в связи с такими факторами как оптимизация всех внутренних процессов компании, законодательная база и исследования эффективности CBD',
  marketplace: '(Вендинг + Маркетплейс)',
  mandatoryPurchase: 'Обязательный\nвыкуп',

  // dao
  dao: 'DAO',
  daoSubhead: 'Инновационный инструмент для организации безопасной инвестиционной деятельности',
  daoText1: 'это инструмент, программный код, который реализуется на блокчейне. Он работает автономно по заранее прописанным условиям и реализуется с помощью смарт-контрактов.',
  daoText2: '<b>DAO является эмитентом TokenShares</b> - крипто-акции, которой будут владеть партнеры компаний. Дивиденды всем акционерам холдинга будут распределяться с помощью TokenShares. Эти криптоактивы позволяют владельцам получать дивиденды в твёрдой валюте, а именно ликвидным активом в долларовом эквиваленте.',
  daoSubhead3: 'Надёжность',
  daoText3: 'Так как все правила прописаны в смарт-контракте, который находится в блокчейне, где каждый может посмотреть правила, и все операции выполняются автоматически.',
  daoSubhead4: 'Безопасность',
  daoText4: 'Смарт-контракты работают автономно, в блокчейне, который валидируется тысячами пользователей по всему миру.',
  panels1: {
    head: 'Организация',
    sub: 'Стандарты организации в 21 веке',
    name1: 'Ограниченная',
    name2: 'Иерархическая',
    name3: 'Обычно не прозрачная',
    name4: 'Только по приглашению',
    name5: 'Не всегда глобальная',
  },
  panels2: {
    sub: 'Децентрализованная Автономная Организация',
    name1: 'Свободная',
    name2: 'Массовая',
    name3: 'Прозрачная',
    name4: 'Открытая',
    name5: 'Полностью глобальная',
  },

  notAvailable: 'Foundation services are not available for your region',
}

export const general = {
  appTitle: 'S-Healthcare - organisation in the medical cannabis industry.',
  name: 'S-Healthcare',
  main: 'Main',
  aboutUs: 'About us',
  market: 'Marketplace',
  missions: 'Aims',
  conditions: 'Conditions',
  cabinet: 'Cabinet',
  month: 'month',
  mission: 'Mission',
  heroDescription: 'Structure of commercial organisations in the <strong>medical cannabis</strong> industry.',

  // about us
  aboutName: 'S-HEALTHCARE <strong>SSG HOLDINGS AG</strong>',
  aboutSubhead: 'Full cycle cannabis innovation',
  ourMission: 'Our mission:',
  aboutDesc1: 'To contribute to the development and widespread international acceptance of medical cannabis as an alternative and sometimes even the only treatment for many diseases. To make the use of medical cannabis locally and financially accessible to all those who need it.',
  aboutDesc2: 'is a Swiss company that realises the full cycle production from cultivation and processing to the delivery of premium finished products in affordable forms to retail outlets around the world.',
  // tab1
  production: 'Production',
  tabTitle1: 'Production Company',
  tabDesc1: '<strong>The company has a goal</strong> of becoming the European market leader in growing, processing and retailing high-potency CBD and THC cannabis through vertical integration of the holding group of companies.',
  tabSlides1: {
    desc1: 'Searching for rental locations, assessing infrastructure, approving conditions, signing contract',
    desc2: 'Ordering and paying for the necessary equipment. Searching for and forming teams to prepare the infrastructure to set up production (tunnels, greenhouses, indoors)',
    desc3: 'Start of equipment installation in tunnels and greenhouse. Start of repair works in the indoors',
    desc4: 'Start of equipment installation in tunnels and greenhouse. Start of repair work in the indoors',
    desc5: 'Procurement of clones, soil, pots, fertilizer. Proceeding with the equipment installation in the indoors',
    desc6: 'Start planting in the tunnels and greenhouse. The final stage of preparing the indoor for cultivation',
    desc7: 'The harvest in the tunnels and greenhouses begins to grow. The first crops in tunnels and greenhouse. Indoor inspection to start production. Start of cultivation in the indoor.'
  },
  // tab2
  extraction: 'Extraction',
  tabTitle2: 'Extraction Company',
  tabDesc2: '<strong>The goal of the company</strong> is to manufacture products based on cannabis oil extract with a high CBD content',
  tabSlides2: {
    desc1: 'Searching for premises. Selection of equipment, obtaining documentation. Paying for equipment',
    desc2: 'Creation of equipment design \na)\xA0GMP design; b)\xA0lighting design; c)\xA0ventilation design; d)\xA0laboratory design;',
    desc3: 'Starting to renovate the facilities',
    desc4: 'Start of equipment delivery \nRecruitment of management team and basic line staff',
    desc5: 'Start of equipment installation',
    desc6: 'Additional month for possible comments from HACCP and GMP and their rectification',
    desc7: 'Pre-commissioning and test run',
    desc8: 'Reaching production capacity'
  },
  // tab3
  sale: 'Sales',
  tabTitle3: 'Sales Company',
  tabDesc3: '<strong>The company\'s objective</strong> is to launch a marketplace that will capture the majority of internet traffic for key searches and purchases of goods containing CBD, as well as to install vending machines across Europe',
  tabSlides3: {
    desc1: 'Selection of vending machine manufacturer, obtaining prices, ordering the first prototype on our design, payment for the sample, delivery of the sample to the office',
    desc2: 'Creation of equipment designs \na)\xA0GMP design; b)\xA0lighting design; c)\xA0ventilation design; d)\xA0laboratory design;',
    desc3: 'Start of renovation',
    desc4: 'Delivery of a batch of small vending machines (by air) \nStarting to place the vending machines at the locations',
    desc5: 'Delivery of a large batch of vending machines. \nStarting to place the vending machines at the locations.',
    desc6: 'Installation from 3 000 vending machines across Europe',
    desc7: 'Ordering a second batch of vending machines \nInstallation of X vending machines',
  },
  // tab4
  products: 'Products',
  tabTitle4: 'Products Company',
  tabDesc4: '<strong>The company\'s task</strong> is to buy raw materials from Production and Extraction, launching production facilities for further drying, sorting and packaging into the final brand for suppling retail chains across Switzerland and Europe',
  tabSlides4: {
    desc1: 'Market and competitors, target audience analysis (Content analysis, SWOT analysis... ) \nBrand - Brand book, naming, design \nPositioning strategy',
    desc2: 'Searching for a head office, signing the contract \nGathering the sales team',
    desc3: 'Ordering the first batch of packings, paying, starting production',
  },

  // market
  marketTitle: 'Market in numbers',
  billion: 'billion',
  marketContent: {
    text1: '<b>More than 50 countries</b> worldwide have legalised some forms of medical cannabis, and six countries have legalised cannabis for adult recreational use.',
    text2: 'Despite widespread global prohibition, more than 263 million people worldwide consume cannabis every year.',
    text3: 'The total global market for cannabis (regulated and illicit) is estimated at USD 340 billion.',
  },
  marketCountry: {
    asia: 'Asia',
    northAmerica: 'North\nAmerica',
    europe: 'Europe',
    africa: 'Africa',
  },

  // objective
  objectiveGoal: 'S&#8209;HealthCare Goal',
  objectiveGoals: 'S&#8209;HealthCare\'s holding goals',
  objectiveTitle: 'Capitalization of fast-growing companies',
  objectiveSubhead1: 'Not just to replicate the success of its US colleagues, but to surpass their performance by taking advantage of technology, state-of-the-art equipment and exclusive production as well as distribution techniques.',
  objectiveSubhead2: 'Expected returns and shareholder value can be GREATLY increased due to the factors such as optimization of all internal company processes, legal framework and CBD performance studies',
  marketplace: '(Vending + Marketplace)',
  mandatoryPurchase: 'Compulsory\nredemption',

  // dao
  dao: 'DAO',
  daoSubhead: 'An innovative tool for organizing safe investment activities',
  daoText1: 'it is a tool, a program code that is implemented on the blockchain. It works autonomously according to predetermined conditions and is implemented using smart contracts.',
  daoText2: '<b>DAO is the issuer of TokenShares</b>, a crypto stock that will be held by the companies\' partners. Dividends to all shareholders of the holding will be distributed using TokenShares. These crypto-assets allow holders to receive dividends in hard currency, namely a liquid asset in dollar equivalent.',
  daoSubhead3: 'Reliability',
  daoText3: 'All the rules are provided in a smart contract, which is in a blockchain where everyone can see the rules, and all transactions are performed automatically.',
  daoSubhead4: 'Security',
  daoText4: 'Smart contracts operate autonomously, in a blockchain that is validated by thousands of users around the world.',
  panels1: {
    head: 'Organisation',
    sub: 'Organisational standards in the 21st century',
    name1: 'Limited',
    name2: 'Hierarchical',
    name3: 'Usually not transparent',
    name4: 'By invitation only',
    name5: 'Not always global',
  },
  panels2: {
    sub: 'Decentralised Autonomous Organisation',
    name1: 'Free',
    name2: 'Large-scale',
    name3: 'Transparent',
    name4: 'Open',
    name5: 'Fully global',
  },

  notAvailable: 'Foundation services are not available for your region',
}
